<template>
  <div>
    <h3>Artikelen in huur</h3>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend
      metus enim, eu tristique dui sollicitudin sit amet. Sed interdum, felis
      quis tincidunt condimentum, nisl elit tempor justo, dictum pulvinar velit
      metus eu eros.
    </p>

    <b-table v-if="items.length > 0" :items="items" :fields="fields" responsive>
      <template v-slot:cell(ReportID)="data">
        <items-modal-info :itemid="`${data.item.ItemID}`" />
      </template>
    </b-table>
    <p v-else>
      U heeft nog geen reserveringen om te bekijken. Wanneer u een nieuwe
      reservering aanmaakt zal deze hier worden getoond.
    </p>
  </div>
</template>

<script>
import { getRentedItems } from '@/services/CustomerItemService'

import { BTable } from 'bootstrap-vue'

import ItemsModalInfo from './ItemModal.vue'

export default {
  components: {
    BTable,

    ItemsModalInfo
  },
  data() {
    return {
      items: [],
      fields: [
        {
          key: 'ItemID',
          label: 'Artikelnummer'
        },
        {
          key: 'Description',
          label: 'Artikel'
        },
        {
          key: 'Amount',
          label: 'Aantal'
        },
        {
          key: 'ReportID',
          label: 'Acties'
        }
      ]
    }
  },
  created: async function() {
    this.items = await getRentedItems({
      customerID: this.$store.state.customerID
    })
  },
  methods: {}
}
</script>

<style scss></style>
