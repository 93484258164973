<template>
  <div>
    <b-button
      :v-b-modal="`modal-${itemid}`"
      class="mb-3"
      size="sm"
      variant="primary"
      @click="showModal"
      ><font-awesome-icon icon="info-circle"
    /></b-button>

    <b-modal
      :id="`modal-${itemid}`"
      size="lg"
      :title="`Artikel informatie ${itemid}`"
    >
      <div v-if="item">
        <table class="table">
          <tr>
            <td><strong>Artikelnummer:</strong></td>
            <td>{{ item.ID }}</td>
          </tr>
          <tr>
            <td><strong>Omschrijving:</strong></td>
            <td>{{ item.Description }}</td>
          </tr>
          <tr>
            <td><strong>Merk:</strong></td>
            <td>{{ item.Brand || 'N/A' }}</td>
          </tr>
          <tr>
            <td><strong>Transport gewicht:</strong></td>
            <td>{{ item.ShippingWeight || 'N/A' }}</td>
          </tr>
        </table>

        <strong>Artikelomschrijving:</strong>
        <hr />
        <div v-html="item.MediumDescription" />
        <div v-html="item.LongDescription" />
      </div>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="secondary"
            class="float-right"
            @click="hideModal()"
          >
            Sluiten
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue'
// import FileDownload from './FileDownload'

import { getItem } from '@/services/ItemService'

export default {
  components: {
    BModal,
    BButton
    // FileDownload,
  },
  props: {
    itemid: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      item: null
    }
  },
  methods: {
    async showModal() {
      this.$bvModal.show(`modal-${this.itemid}`)
      this.item = await getItem({ itemID: this.itemid })
    },
    hideModal() {
      this.$bvModal.hide(`modal-${this.itemid}`)
    }
  }
}
</script>
